
/* header and heroimage media query styling here
 */
    @media only screen and (max-width:992px) and (min-width:800px){
   .hero-image-inner-item img{
    margin-top:8rem;
    margin-left:-5rem !important;
    }
    .hero-image-inner-item>.item>.ogene{
    transform: scale(1.5) !important;
    z-index:2 !important;
    position:absolute;
    left:0 !important;
    top:3rem !important;

    }
    .hero-image-inner-item>.item>.logo{
    left:3rem !important;
    top:2rem;
    transform:scale(1.8) !important;
    background:black;
    z-index:-1 !important;
    max-height:14rem !important;
    max-width:14rem !important;
        
        }



    }
    @media only screen and (max-width:800px) and (min-width:600px){
    .hero-image-inner-item>.item> img{
     margin-top:10rem;
     margin-left:-4rem;
    }
    .hero-image-inner-item>.item>.ogene{
    transform: scale(1.5) !important;
    z-index:2 !important;
    position:absolute;
    left:5% !important;
    top:3rem !important;

    }
    .hero-image-inner-item>.item>.logo{
    left:3rem !important;
    top:2rem;
    transform:scale(1.5) !important;
    background:black;
    z-index:-1 !important;
    max-height:14rem !important;
    max-width:14rem !important;
    
    }
    .main-card>.hero-card-body p{
      max-width: 300px !important;
    }
   }  
  
   @media only screen and (max-width:600px) and (min-width:320px){
    .hero-image-inner-item>.item> img{
     margin-top:12rem;
     margin-left:-8rem;

    }
    .hero-image-inner-item>.item>.ogene{
    transform: scale(4.5) !important;
    z-index:2 !important;
    position:absolute;
    left:0 !important;
    top:3rem !important;

    }
    .hero-image-inner-item>.item>.logo{
    left:0 !important;
    top:2rem;
    transform:scale(1) !important;
    background:black;
    z-index:-1 !important;
    max-height:14rem !important;
    max-width:14rem !important;
    
    }
    
   }  
  


/*About us media query  starts here*/
.About{
    z-index:10;
    position:relative;
    box-sizing: border-box;
    padding: 0 5rem !important;
    .our{visibility: hidden !important;}
    .CV{margin-left:-2rem !important;}
     .title{color:rgb(15, 15, 15);
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 700;
   
    }
    .page-about{
        color:rgb(34, 34, 34);
        position: relative;
    }
    @media(max-width:1000px){
      
        .reg-button{
            margin: 0 !important;
        }
    }
    @media(max-width:1012px){
        padding: 0 2rem !important;
      
    }
    @media(max-width:980px){
        padding: 0 !important;
        & .left-aside{
            padding-left:3% !important;
        
        }

    }
    @media(max-width:879px){
        .horrizontal{
            display:none !important;
          }
           
.igbo-value,
.join,
.must,
.child,
.pencil{
display:none !important;
}
    }
   }  
  


     
.culture-title{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 600;
    
    
}

.left-aside{
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    padding-right:5%;
    padding-bottom:3%;
}
.right-aside{
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}

@media(max-width:786px){
   
.about-us{
display:flex;
flex-direction: column;
justify-content:center;
align-items:center;
.our{visibility: visible !important;}
.CV{margin-left:-3.8rem !important;}
;& :not(.envi-list){
padding:0 5%;
} 
;& .right-aside, .left-aside{
min-width:100% !important;
max-width: 100% !important;
}

;& .title{
    font-weight: 600;
    font-size: 1.7rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.right-aside {
    margin-top: 2rem !important;
  }

.envi-list{
  padding: 5% 1% !important;
  height:auto; 
  font-weight:600 !important ;
  font-size: 1.1rem !important;
}

.culture-title{
    font-weight: 700;
    font-size:1.3rem !important;
    min-width:100% !important;
    position: absolute !important;
    left:0;
   /*  position: absolute;  */
}


}

}
@media(max-width:600px){
    .CV{position: relative;
        left:1.8rem !important;

}
}

.reg-button{
    border:1px solid #170e35 !important;
    box-sizing:content-box;
    padding: 1rem;
    border-radius: 10px;
     background:#A6A6A6 !important;
     color: #170e35 !important;
     display: flex;
     align-items: center;
     justify-items: center;
     font-weight: 800;
     outline:none !important;
     :hover{
    color:rgb(240, 11, 11);
    
     }
}

@media(max-width:786px){
    
    .page-about, h3{
    position: relative;
    }
    .reg-button{
    display: block;
    min-width: 150px;
    padding:1% 0 !important;
    margin-left: -5% !important;
    right:5%
    }
   }

.jumbo1{background:#fafafb;
}
