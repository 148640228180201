

.cons-tables{
 min-height:100vh !important;
 max-height: 100vh !important;
    background:#fafafa;
    overflow-y: scroll;
    padding: 2% 0;
}

.PDF-cover{
    min-height:700px;
    max-width: 80%;
    min-width: 80%;
    height:auto !important;
    transform: translateY(2%);
    tbody{
        tr{
            td{
                &:nth-child(2){
                    text-align: center !important;
                    @media(max-width:500px){
                     
                            font-size: 14px !important;
                            text-align: left !important;
                    
                    }
            }
   
        }
        }
    }

  @media(max-width:992px){
      margin: 0 auto !important;
      max-width: 80vw;
      min-width: 80vw;
    }
  @media(max-width:786px) and (min-width: 300px){
        padding:0 1% ;
    
    }
 
  
    tbody{
        border: 1px solid #000;
        max-width:100% !important;
        min-width: 100% !important;
        border-left: 1px solid #000;
        }
        tr{
         
           
            td{
                min-height:5px !important;
                padding:3px !important;
                text-align: left !important;
                border-left: 1px solid #000;
                border: 1px solid #000;
                border-left: 1px solid #000;

            }
        }
    }
  
    .donation-text{
        font-size:1.2rem !important;
        display:flex;
        justify-content: start;
        align-items: center;
        text-align: left;
        min-width:100%;
    }
.donation-text-space{
    font-size:2rem !important;
    visibility: hidden !important;
}
.total{
   
    visibility: hidden !important;
    border-bottom:3px solid rgb(252, 251, 251)  !important;
}

.bullet{list-style-type:circle !important;
width:5px !important;
height:5px !important;
border-radius: 50%;
}
.horrizontal{
    position: relative;
    top:6rem !important
}
.horrizontal2{
    position: relative;
    top:8rem !important
}
.horrizontal3{
    position: relative;
    top:9rem !important
}
.constitution-table{
    color:#000;
}
p{
    color:black !important;
}
b{margin:0 5px}
.li-circle{list-style-type:circle !important;}
.li-alpha{list-style-type:lower-alpha !important;}
.li-number{list-style-type: numeral}
  