


.sign-ups{
  padding: 0;
  margin-top: 0;
  box-sizing: border-box;
  background:url(../components/image/lightgreen.jpg)center/cover no-repeat;
  min-height:100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media(max-width:786px){
   padding: 0 1.4rem;
  }
  
    
  h5{
    font-family: "Black Ops One", system-ui;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;

  }


  .account-condition a {
    background-color: red !important;
    background-image: linear-gradient(45deg, #f3ec78, #af4261) !important;
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent !important;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent !important;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
  }
  label {
    font-size: 0.8rem;
    font-weight: 600;
    color: ivory !important;
    z-index: 2500;
  }
  .btn-lg.btn-info.form-control {
    font-size: 1.4rem;
    font-weight: 700;
    z-index: 1000;
    background: rgb(228, 157, 5) !important;
    border: none;
    text-shadow: -1px 0px 2px rgba(0, 0, 0, 0.6);
    :focus {
      box-shadow: none;
    }
  }
  .nav-brand {
    z-index: 2500 !important;
  }
  .title-headers {
    z-index: 2500;
    h4 {
      color: #00fb1d;
      background: transparent;
      text-shadow: 3px 5px 2px #474747;
      z-index: 2500;
    }
  }
  
}
.form-containers {
  transform: translateY(2rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem !important;
  padding-bottom: 4rem;
  padding-top: 4rem;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  h3{
    font-family:Arial, Helvetica, sans-serif;
    font-weight: 900;
    font-size:3rem;
    
  }
  h6{
    color: rgb(205, 241, 119) !important
  }

  .forms {
    z-index: 3000;
    min-height: 100%;
    height: auto;
    min-width: 60%;
    @media(max-width: 786px){
      min-width: 100%;
    }

    input {
      margin-bottom: 0.8rem;
      padding: 0.8rem;
      border: none;
      :focus {
        box-shadow: none;
        border: none;
      }
    }
  }
}