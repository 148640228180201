/*NavbarNew styles */




.off-canva{
  z-index: 3000;
  background: #181123 !important; 
  min-height: 100vh !important;
  height:auto;

  }
.nav-cover{
transform: translateY(-8px);
background:url(../components//image/imagea.png)center/cover no-repeat;
 z-index: 18000;
  min-width:100vw !important;
  min-height: 100px !important;
  max-height: 100px !important;
  overflow: hidden;
  padding:0;
/*   background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(8,1,43,0.9864320728291317) 35%, rgba(14,1,31,1) 100%);
 */

  ::after{
    content:"";
    min-width:100%;
    min-height:100%;
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
/*     background: rgba(0, 0, 0, 0.23);
 */    z-index: -2;

  } 
  @media(max-width:992px){
    -webkit-filter: brightness(1.2) contrast(1.2)  !important;;
   -moz-filter: brightness(1.2) contrast(1.2)  !important; ;
    filter: brightness(1.8) contrast(1.8) !important;
    padding: 0 1rem !important;
   
  }
 
& .brand-logo{
  min-width:450px;
  z-index:17000 ;
  display:grid;
  grid-template-columns: repeat(3, auto);
  justify-content:flex-start;
  align-items: center;
  max-height:100%;
  gap:5;

  & .logo-text {
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    color:rgb(247, 245, 245);
    gap:1rem !important;
    z-index: 17000;
 

  }

 & .logo {
  font-size: 1.9vw;
  font-weight: 900;
  line-height: 1;
  color: transparent;
  z-index: 17000;
  background: rgb(243, 243, 247);
  background: linear-gradient(90deg, rgb(251, 251, 253) 0%, rgba(121,9,109,1) 35%, rgb(255, 174, 0) 100%);
  -webkit-background-clip: text;
  position: relative;
  @media (max-width: 786px) {
    left: -1rem;
  }
  @media (max-width: 500px) {
    left: -0.7rem;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 2rem;
    left: 0;
    height: 50%;
    background: linear-gradient(to right, #11012b, #9c5f03, #070306);
    border-bottom-right-radius: 50%;
  }
}
}
@media(max-width:1160px){
  .brand-logo{
    margin-left: -2rem !important;
    min-width:500px !important;
    max-width:500px !important;
  }
  
  .chevron-down{
    transform: translateX(-2rem) !important;
    z-index: 7000;
  }
  }

 .buttons-right{
  min-width:300px;
  display:grid;
  grid-template-columns: repeat(2, auto);
  justify-content:flex-end;
  align-items: center;
  & .btn1,.btn2{
    border:none !important;
    outline:none !important;
    background-color: red;
    background-image: linear-gradient(45deg, #f3ec78, #af4261);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
    font-size: 1.2rem;
    font-weight: bold;
    font-family: Arial, sans-serif;
    & span{
      text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      -webkit-text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      -moz-text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }   
 
  }
}
& .navbar-toggle{
  z-index:7000 ;
  max-width:35px;
  font-size: 2rem !important;
  background:transparent;
  box-shadow: none !important;


  .btn-menu{
  display:flex;
  justify-content: center;
  align-items: center;
  gap:5px;
  background: transparent !important;
  border:none !important;
  z-index: 7000;
  @media(max-width:992px){
    position: relative;
    margin-left:6rem !important;
  }
  .btn-icon {
    z-index:7000 ;
  position: relative;
  top:1px !important;
  font-size: 1rem !important;
    }
    &:focus{
    box-shadow:none;
    outline:none;
    }


@media(max-width:992px){
  position: relative;
  left:3rem !important;
}
  @media(max-width:786px){
    display:none !important;
  }
} 
 & .Bjustify,.chevron-down:hover {
    cursor: pointer;
  &:not(.chevron-down){
    display:none !important;
@media(max-width:786px){
  display:block !important;
  margin-left:2rem;
}
 }

}
}



/* Media queries */
@media (max-width: 992px) {
  .brand-logo{
    max-width: 100px !important;
    min-width: 100px !important;
    margin-left: 1rem !important;
    & .image{
       min-width:300px !important;
       max-width:300px !important;
       min-height:200px !important;
       max-height:200px !important
    }
    .logo-text {
    display: none !important;
    }
    & .logo{
      font-size: 1.8rem !important;
    }
    
  
  }
 

}

@media (max-width: 786px) {
  .update{
    position: relative;
    left:2rem !important;
    @media(max-width:600px){
     display:none !important;
    }
  }
  .buttons-right{
    display: none !important;
  }

}
@media(max-width:500px){
  .brand-logo{
    margin-left: -2rem !important;
    & .logo{
        font-size: 2rem!important;
      &::after{
        top:1.7;


      
      }
    }
  }
  .Bjustify{
    margin-left: 4rem !important;
    z-index:4000 ;

  }
}
}
.navbar-off-canva-container{

min-width:97vw;
margin: auto;
min-height: 100%;

 
}

.btn-sign-in{
  span{
  z-index: 20000 !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
} 


/*   font-size: 1.3rem !important;
  font-family: Arial, Helvetica, sans-serif !important;
  font-weight: bold;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(48, 13, 13, 0.3) 0px 18px 36px -18px inset !important;
  margin-top:-1rem; 
  background: #1515159b !important;
  color:rgb(255, 149, 36);
  border-radius: 10px; */
  .donated-page {
    font-size: 1.2rem;
    margin-top: -10px;
    text-transform: uppercase;
    z-index: 1000;
    color: rgb(255, 149, 36) !important;
    border: 1px solid #462602 !important;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5) !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2) !important;
    min-height: 20px !important;
    width: 90px !important;
    font-weight: 700 !important;
    background: #1515159b !important;
    transition: all 1s ease-in-out;
    animation: swing 3s infinite;
  }
  
  /* Media Queries */
  @media (min-width: 997px) {
    .donated-page {
      margin-top: -0.9rem; 
      color: white !important
    }
  }
  
  @media (max-width: 786px) {
    .donated-page {
      font-size: 1rem !important;
      width: auto !important;
      left: 1rem !important;
      background: #151515f7 !important;
      
    }
  }
  
  @media (max-width: 410px) {
    .donated-page {
      font-size: 0.8rem !important;
      width: auto !important;
      left: -3rem !important;
      background: #151515f7 !important;
      
    }
  }
  
  
  

  
 
  
  /* Keyframes */
  @keyframes swing {
    0%,
    100% {
      transform: rotate(0deg); 
      background-color: red; 
    }
    25% {
      transform: rotate(15deg); 
      background-color: blue; 
    }
    50% {
      transform: rotate(-10deg);
      background-color: green; 
    }
    75% {
      transform: rotate(5deg); 
      background-color: #610474; 
    }
  }
  
 
  

  
