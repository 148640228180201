


.modal-containers {
 height: 100%;
 width:100%;
  background-color: rgba(15, 12, 12, 0.7) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 950000;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;

}
.modal-content {
  max-width:80%;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 30px;
  position: relative;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  animation: slideIn 0.4s ease forwards;
  font-family: Arial, sans-serif;
  z-index:350000;
  margin: auto;
  @media(max-width:786px){
    min-width:100% !important;
  }


}

.modal-container.show {
  opacity: 1;
  visibility: visible;
} 

@keyframes slideIn {
  from {
    transform: translateY(-30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}


.modal-content h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-top: 0;
}


.modal-content p {
  font-size: 1rem;
  color: #555;
  line-height: 1.5;
  margin-bottom: 15px;
}


.modal-content label {
  display: flex;
  align-items: center;
  margin-top: 15px;
  color: #4b4b4b;
}

.modal-content input[type="checkbox"] {
  margin-right: 10px;
}


.modal-content button {
  display: inline-block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.modal-content button:hover {
  background-color: #0056b3;
}


.modal-content .cancel-button {
  background-color: #fd214d;
  color: #333;
}

.modal-content .cancel-button:hover {
  background-color: #1b1a1a;
}


.modal-content .error-text {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 10px;
}
 
h5{
  font-size: 1.2rem !important;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: capitalize !important;
  @media(max-width:400px){
    font-size: 1rem !important;
  }

} 

p span{
color:red !important
}
h4{
  font-size: 1.2rem !important;
  @media(max-width:400px){
    font-size: 1rem !important;
  }
}

