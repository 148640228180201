
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Oswald:wght@200..700&family=Playball&display=swap');

/*   font-family: "Bebas Neue", sans-serif;
  font-weight: 900;
  font-style: normal; 
  font-family: "Playball", cursive;
  font-weight: 400;
  font-style:oblique; 
  font-family: "Oswald", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;*/

  .gsap-cover {
    position: relative;
    display: flex;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 100;
    max-width:80vw;
    display: flex;
    min-height:60vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top:-1rem !important;
    left:3rem;
    padding: 1rem 0 !important;
    @media(max-width:786px){
      min-width:90vw;
      min-height:50vh;
      left:0;
      
    }
    @media(max-width:400px){
      min-width:100vw;
    }
  
    .text-shadow {
      text-shadow: 2px 2px 4px rgb(0, 0, 0); /* Optional, to improve text readability */
    }
    
      .d-flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
      }
  
    video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    border-radius: 2%;
   @media(max-width:786px){

 }

  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); /* Adjust the opacity to your preference */
    z-index: 0;
    border:1px solid rgba(255, 166, 0, 0.025);
    border-top-left-radius:5px;
    border-top-right-radius:5px;


  }
  
  div.gsap-container {
    position: absolute;
    top:0;
    left:0;
    z-index: 1;
    display: flex;
    padding: 1rem 0;
    flex-direction: column;
    justify-content:center;
    align-items: flex-start;
    width:100%;
    height:100%;
      
    }
  div.title-body {
  position:absolute;
  top:3px;
  bottom:0;
  left:0;
  right:0;
  width: 100%;
  height:100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;

@media(max-width:786px){
  height:100%;
  height: auto;
  justify-content:left;
  width: 100%;
  padding-left:0 !important;
  padding-top:10px !important;
 
}
@media (max-width: 430px) {
/*   top:2rem !important; */
}
}  

    
    h1,h4,h5,h6{
     z-index: 500;
      &:not(h4,h5,h6)
      {
        color: #ffffff !important;
        font-family: "Bebas Neue", sans-serif;
        font-weight: 600;
        letter-spacing: 1px;
        font-style: normal;
        text-align: left !important;
        font-size: 52px;
      };
      &:not(h1,h5,h6){
        color:rgb(208, 163, 0);
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
        font-weight: 800;
        font-style: normal;
        font-size: 1.5rem;
      }
      &:not(h1,h4,h5){
        color:rgb(26, 248, 10);
        font-family: "Oswald", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-size: 1.5rem;
      }
      }
    
h5{
  font-family: "Playball", cursive;
    @media (max-width: 768px) {
        display: none;
      }
    }

    @media (max-width: 786px) {
      h1{
        font-size:2rem !important;
        font-weight: 400 !important;
        text-align: left !important;
       
      }
      h4, h6{
        font-size:1.5rem !important;
        text-align: left !important;
       
      }

    }

    @media (max-width: 430px) {
      h1{
        font-size:1.6rem !important;
        text-align: left !important;
      }
      h4, h6{
        font-size:1.3rem !important;
        text-align: left !important;
      }

    }
  }