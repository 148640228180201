/* Update page layout */
.update-page {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .update-header {
    position: relative;
    min-height: 200px;
    max-height: 200px;
    position: relative;
    overflow: hidden;
    
    img{
      background-position: 45% 75% !important;
      background-repeat: no-repeat;
      background-size:contain !important;
      display: block;
      position: absolute;
      min-height:100%;
      min-width:100%;
      top:-12rem !important;
      @media(max-width: 786px){
        top:0rem !important;
        
      }
      
   
   

    }
  }
  .page-description{
    min-width: 100vw;
    text-align: left;
    min-height:50px;
    height:auto;
    color:#f0f5f1 !important;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
   
  } 
  
  .overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .body {
    position: relative;
    top:-2rem !important;
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px;
  
    }
    .s-2,
    .s-3{
        display:none;
    }
  
  .section {
    width: calc(50% - 20px);
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 30px;
    padding: 20px;
  }
  
  .section h3 {
    font-size: 24px;
    margin-bottom: 10px;
    color: blue;
  }
  
  .section p {
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Responsive layout */
  @media screen and (max-width: 992px) {
    .update-header {
      height: 300px;
    }
  
    .overlay h1 {
      font-size: 36px;
    }
  
    .section {
      width: 100%;
    }
  
  }
  
  @media screen and (max-width: 480px) {
    .update-header {
      height: 200px;
    }
  
    .overlay h1 {
      font-size: 24px;
    }
  
  }
  