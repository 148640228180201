

.register {
  position: relative;
  right: 20%;
  top: 15%;
}

/*---------------------OPEN MENU----------------------------------*/


/*-----------REGISTRATION FORM BY MODAL----------------*/

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: white !important;
  background-color: #ea4335;
}

.btn-facebook {
  color: white !important;
  background-color: #3b5998;
}


.reg-btn:hover {
  background-color: dimgray;
  color: white;
}



/*---------MEDIA Querry-------*/

