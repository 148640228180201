
.logn-section{
    position: relative;
    background:url(../components/image/GREEN_1.webp)center/cover no-repeat;    
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    z-index:1000 ;
    @media(max-width:786px){
      padding: 0 1.5rem !important;
    }

    
    h2{
        font-family: "Black Ops One", system-ui;
        font-weight: 400;
        font-style: normal;
        text-transform: uppercase;
        color: white !important;
        z-index:1000 ;

      }
    
}

