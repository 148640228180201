

/*Minutes of the meeting by date starts here*/
.slide-title{
visibility: hidden;
z-index:1000 ;

@media(max-width:992px){
visibility: visible;

}
}

/* MINUTES AND PAGES HERE */
.preceeding{
   font-size: 1.2rem !important;
   z-index:1000 ;

}


/*Sign In Pages Start Here*/
.sign-input{
    border-top:none !important;
    border-right:none !important;
    border-left:none !important;
    border-radius: none !important;
    box-shadow:none !important;
    border-bottom:2px solid slategray !important

}

    
    @media (min-width: 768px) {
    .gradient-form {
    height: 100vh !important;
    }
    }
    @media (min-width: 769px) {
    .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
    }
    }
    .Auth-btn{
        display:grid;
        grid-template-columns: repeat(2, auto);
        gap:5%;
    }
    .logout-btn{
        background: transparent !important;
        border: none !important;  
        outline:none !important;
        box-shadow:none ! important;
        :focus{
         outline:none;
         border:none;
         box-shadow:none;
        }
      
       }
          .register{
            position:relative;
            left :25% !important;
            bottom:15%;
           
          }
          .span-log{
            font-size: 1.2rem;
            font-weight: 800;
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
            background-image: url('../components/image/color-mix1.jpg');
            
          }  
    