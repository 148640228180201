

   .rota-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: black;

  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    color:black;


  }
  
  .description {
    font-size: 20px;
    margin-bottom: 20px;
    color:black;
  }
  
  .rota-table {
    width: 100%;
    border-collapse: collapse;

  }
  
  .rota-table th,
  .rota-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
    color:black !important;
   
  }
  .rota-table td:nth-child(1){
    text-align: left !important;
  } 
  
  .rota-table th {
    background-color: #eee;
    font-weight: bold;
    text-align: center;
  }
  
  .rota-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  @media (max-width: 768px) {
    .rota-table {
      font-size: 14px;
      font-weight: 600;
    }
    .rota-table th,
    .rota-table td {
      padding: 6px;
    }
    .title {
      font-size: 24px;
    }
    .description {
      font-size: 16px;
    }
  }
  