

.video-cover{
min-height:100vh;
height:auto;
margin:auto;
padding-bottom:1rem;
padding:2rem 6rem !important;
@media(max-width:992px){
  padding:0 7rem !important;
  overflow-y: scroll;
}
@media(max-width:786px){
  padding:0 3rem !important;
  overflow-y: scroll;
}


}

.video-wrapper {
  background-color: #551acb;
  padding: 1rem !important;
}
.playlist-wrapper {
  padding-right: 2%;
  -webkit-box-shadow: -10px 0px 13px -7px #000000, 
  10px 0px 13px -7px #000000,
   5px 5px 15px 5px rgba(0,0,0,0); 
  box-shadow: -10px 0px 13px -7px #000000,
   10px 0px 13px -7px #000000, 
   5px 5px 15px 5px rgba(0,0,0,0);
   transform: translateX(7%);
/*    background: #31360b !important;
 */  
  }

.video-playlist video {
  width: 100%;
  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000, 5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.video-playlist .video-details {
  margin-top: 20px;

 
}
.video-details h4 {
  font-size: 1.3rem;

}


 .playlist-item {
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  padding: 0 10px !important;
  border: 1px solid #ccc;
  -webkit-border-radius: 15px;
-moz-border-radius: 15px;
border-radius: 15px;
min-width:100%;

 
}

.video-playlist .playlist-item:hover {
  background-color: #646161;
}


@media(max-width:992px){
 
  .video-playlist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

.playlist-wrapper {
  min-width: 100% !important;
  margin-top:2% !important;
  transform: translate(0%, 5%) !important;

 }
 .video-wrapper {
  min-width:100%;
  padding:1rem !important;
video{
  object-fit: cover;
  object-position: center center;
}
 }

}
.vd-desc{
  color:#b493f7 !important;
}

